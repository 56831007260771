import React,{useState, useEffect, useRef} from 'react'
import { Container, Button, Grid  } from 'semantic-ui-react'
import styled, {keyframes} from 'styled-components'
import { ServiceData } from './TopMenu/TopmenuData'
import { serviceTab, MemberData, mainImageList } from "./bodyDataList";
import { ExtLinkButton } from './header'
import CardSlider from './cardSlider';
import MemberCard from './memberCard';
import ModalExampleBasic from './memberModal'

function Body() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [rotateImage, setRotateImage] = useState(0)

  const externalLink = (link) => {
    window.location.href= link;
}

  return (
    <BodyContainer>
      <FlexAroundWrapDiv>
        <div style={{ alignSelf: "center" }}>
          <H1BodyTitle>ByLabs</H1BodyTitle>
          <div style={{display:'flex',flexWrap:'wrap', justifyContent:'center',}}>
            <div style={{display:'flex'}}>
          <span style={{fontSize:'20px', lineHeight:'28px', textAlign:'justify'}} >
            ByLabs is a decentralized development organization focused on
            blockchain and metaverse-related projects that operates beyond the
            traditional corporate structure and geographical location
            boundaries. Our members have the freedom to form sub-organizations
            and work collaboratively based on project requirements and available
            resources.<br/><br/> As the first metaverse-based development organization in
            Korea, we are committed to pushing the boundaries of innovation and
            exploring new opportunities in this exciting field.
          </span>
          </div>
          </div>
        </div>
      </FlexAroundWrapDiv>
      <div>
        <ContainerWrap>
          <p
            style={{
              textAlign: "center",
              color: "#3478f6",
              fontWeight: "700",
              fontSize: "1.5rem ",
            }}
          >
            ByLabs PRODUCTS
          </p>
          <h1 style={{ textAlign: "center" }}>Our current services</h1>
        </ContainerWrap>
        <FlexEvenlyDiv>
          {serviceTab.map((e) => {
            return (
              <p
                onClick={() => {
                  setSelectedTab(e.key);
                }}
                style={{ cursor: "pointer" }}
              >
                {e.name}
              </p>
            );
          })}
        </FlexEvenlyDiv>
        
        <Box key={selectedTab}>
          <div style={{ marginRight: "80px" }}>
            <h2>{serviceTab[selectedTab].header}</h2>
            {serviceTab[selectedTab].desc}
            <div style={{display:'flex'}}>
            <ExtLinkButton
              content={ServiceData.menuData[selectedTab].name}
              type={"button"}
              onClick={() =>
                externalLink(ServiceData.menuData[selectedTab].url)
              }
            />
            {selectedTab === 1 && (
              <ExtLinkButton
                content={ServiceData.menuData[selectedTab + 2].name}
                type={"button"}
                onClick={() =>
                  externalLink(ServiceData.menuData[selectedTab + 2].url)
                }
              />
            )}
            </div>
          </div>

          <ServiceImageDiv>
            <TabImage alt="tabImage" src={serviceTab[selectedTab].image} />
          </ServiceImageDiv>
        </Box>
      </div>
      <ContainerWrap>
        <h1 style={{ margin: "0px 0px 80px 0px" }}> Team Members</h1>
        <MemberGrid columns={4} >
        {MemberData.map((e,i) =>  <ModalExampleBasic dataObject={e} index={i} />)}
       </MemberGrid>


        
      </ContainerWrap>
    </BodyContainer>
  );
}
const boxFade = keyframes`
0%{
opacity: 0
}
50%{ 
   opacity: 0.5;
}
100%{
  opacity: 1;
}
`;

export const Box = styled.div`
padding-top: 30px;
display: flex;
justify-content: space-evenly;
div {
  animation: ${boxFade} 2s linear ;

}
`;
export const ServiceImageDiv = styled.div`
  width: 200px;
  height: 200px;
  @media only screen and (max-width: 767px) {
    width: 100px;
    height: 100px;
    align-self: center;
  }
`;

export const GridColumns = styled(Grid.Column)`
text-align: -webkit-center;
background:#ffffff;
padding: 0px 0px 0px 0px !important;
`
export const GridRows = styled(Grid.Row)`
  background: #f5f5f5 !important;
`;
export const MemberJob = styled.div`
  width: 100%;
  height: 50px;
  color: #e1272f;
  font-weight: 700;
  font-size: 16px;
`;
export const MemberNameH2 = styled.h2`
  width: 100%;
  height: 50px;
  padding-top: 20px;
  padding-bottom: 10px;
`;
export const H1BodyTitle = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;
export const DescDiv = styled.div`
padding-left: 10px;
padding-right: 10px;
font-style: italic;
`
export const CardDiv = styled.div`
  height: 310px;
  background: #ffffff;

`;
export const ImgCardDiv = styled.div`
  max-width: 310px;
  max-height: 310px;
  background: #f5f5f5;
`;
export const MemberCardImage = styled.img`
width:100%;
height:100%;
object-fit: fill;
animation: ${boxFade} 2s linear ;
`


export const TabImage = styled(MemberCardImage)`
  padding-right: 30px;
  height: auto;
  @media only screen and (max-width: 767px) {
    width: auto;
    height: 100%;
  }
`;
export const ContainerWrap = styled.div`
  padding: 10px 0px 30px 0px;
  margin-top: 70px;
`;
export const FlexAroundWrapDiv = styled.div`
display: flex;
justify-content: space-around;
flex-wrap: wrap;
margin: 10em 0em 20em 0em;
`;
export const FlexEvenlyDiv = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 50px 0px 30px 0px;
`;
export const BodyContainer = styled(Container)`
  width: 135vh !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-bottom: 4rem !important;
`;
export const GridRowBackground = styled(Grid.Row)`
  width: 80% !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-bottom: 4rem !important;
`;
export const MainImageDiv = styled.div`
padding: 50px 0px 20px 0px;
width: 700px;
height: 700px;
`;
export const MemberGrid = styled(Grid)`
padding: 0px;
@media only screen and (max-width: 767px) {
justify-content: center;
}
`

export default Body;
