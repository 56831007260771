import DropdownTopMenu  from './TopMenu'
import {Link} from 'react-router-dom'
import { Menu, Container, Button } from 'semantic-ui-react'
import { ServiceData, homePagesData } from "./TopMenu/TopmenuData";
import styled from 'styled-components'
import byLabsLogo from '../img/byFactoryLogo.png';
function Header() {
  const externalLink = (link) => {
    window.location.href= link;
}

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <LogoMenuDiv>
          <LogoToLink to="/">
            <img
            style={{marginRight: '40px', marginLeft:'30px'}}
              alt="ByLabs.io"
              height="80"
              width="80"
              src={byLabsLogo}
              className="logo"
            />

          </LogoToLink>

          <MenuGridDiv  style={{marginRight: '50px'}}>
            <DropdownTopMenu
              menuTitle={ServiceData.tabName}
              tapTitle={ServiceData.tabName}
              dropdownMenu={ServiceData}
            />
          </MenuGridDiv>
        </LogoMenuDiv>
      </HeaderWrapper>
    </HeaderContainer>
  );
}

export const ExtLinkButton = styled(Button)`
  color: #ffffff !important;
  background: #3478f6 !important;
  width: 700px;
  max-width: 122px;
  height: 50px;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  border-radius: 12px !important;
  &&&:hover {
    color: #fff !important;
    background: #3c82f6 !important;
  }
`;

export const ExtLinkButtonWrapper = styled.div`
align-items: center; 
display: flex; 
margin-right: 50px;
`
export const MenuGridDiv = styled.div`
display: flex;

`

export const HeaderDiv = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
border-bottom: 1px solid #f2f2f2;
`
export const HeaderContainer = styled(Container)`
  background: rgb(255, 255, 255);
  width: 100% !important;
  height: 90px;
  position: sticky;
  top: 0px;
`;
export const LogoMenuDiv = styled.div`
align-items: center;
display: flex;
height:100%;
justify-content: space-between;
padding-left: 30px;
`;

export const HeaderWrapper = styled.div`
width: 100%;
height: 100%;

border-bottom: 1px solid rgb(242, 242, 242);
`
export const LogoToLink = styled(Link)`
margin-right: 24px;
align-items: center;
display: flex;
`

export default Header;
