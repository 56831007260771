import byFactoryLogo from '../img/byFactoryLogo.png';
import prenetLogo from '../img/prenetLogo.png';
import bySuitsLogo from '../img/bySuitsLogo.png';
import miner from '../img/members/miner.png'
import summer from '../img/members/summer.png'
import jongkyuPark from '../img/members/jongkyuPark.png'
import sangminDo from '../img/members/sangminDo.png'
import solKang from '../img/members/solKang.png'
import jonginLee from '../img/members/jonginLee.png'
import chanhuiLee from '../img/members/chanhuiLee.png'

export const serviceTab = [
  {
    name: "ByFactory",
    key: 0,
    header: "ByFactory",
    image: byFactoryLogo,
    desc: (
      <p>
        ByFactory is a decentralized community platform <br />
        that makes it easy to create and run a DAO <br />
        without the need for developers.
      </p>
    ),
  },
  {
    name: "PRENET",
    key: 1,
    header: "PRENET",
    image: prenetLogo,
    desc: (
      <p>
        PRENET is klaytn's private layer 2 blockchain,
        <br />
        operated in cooperation with Busan TP.
      </p>
    ),
  },
  {
    name: "BySuits",
    key: 2,
    header: "BySuits",
    image: bySuitsLogo,
    desc: (
      <p>
        It shows you in advance what will happen when you<br/> transact with KAIKAS,
        so you can check for fraud before<br/> the transaction is executed.
      </p>
    ),
  },
];

export const MemberData = [
  {
    data: {
      name: "Miner",
      desc: (
        <p>
          Meet our Development Lead, Miner, the wizard of blockchain and
          metaverse development. With a passion for design and a knack for
          cutting-edge technology, Miner is always pushing the envelope of
          what's possible. Whether it's experimenting with new tools or
          fine-tuning our development process, He is always at the forefront of
          innovation. So if you want to see the future of blockchain and
          metaverse, look no further than Miner!
        </p>
      ),
      cardDesc: <p>Meet our Development Lead, Miner</p>
    },

    image: miner,
  },
  {
    data: {
      name: "Summer",
      desc: (
        <p>
          Introducing our Business Leader, Summer, the mastermind behind our
          mission to create a more fun, transparent, and equal world. With a
          creative vision and a passion for social impact, Summer is always
          exploring new ideas and partnerships that can make a positive
          difference. Whether it's developing a new business strategy or forging
          new connections with like-minded organizations, she is always one step
          ahead of the game. So if you want to join us in changing the world,
          Summer is the one to talk to!
        </p>
      ),
      cardDesc: <p>Introducing our Business Leader, Summer</p>
    },
    image: summer,
  },
  {
    data: {
      name: "Jongkyu Park",
      desc: (
        <p>
          Say hello to our Planning Leader, Jongyu, the maestro of organization
          and coordination. With a talent for dividing and managing tasks,
          Jongyu ensures that our developers can focus solely on what they do
          best: building awesome tech! But don't be fooled by his attention to
          detail and precision - underneath that serious exterior lies a heart
          of gold, always ready to lend a helping hand or share a joke. So if
          you want to work hard and have fun doing it, Jongyu is your go-to
          person.
        </p>
      ),
      cardDesc: <p>Say hello to our Planning Leader, Jongyu</p>
    },
    image: jongkyuPark,
  },
  {
    data: {
      name: "Sangmin Do",
      desc: (
        <p>
          Meet our Metaverse Team Leader, Sangmin, the visionary behind our most
          creative projects. With an eye for stunning 3D content and a deep
          knowledge of the latest technology trends, Sangmin is always striving
          to create something truly innovative. But he is not just a dreamer -
          he is also a dedicated and hardworking team leader who inspires
          everyone around them to be their best. So if you're looking for a
          leader who knows how to blend creativity and hard work, Sangmin is the
          one for you!
        </p>
      ),
      cardDesc: <p>Meet our Metaverse Team Leader, Sangmin</p>
    },
    image: sangminDo,
  },
  {
    data: {
      name: "Sol",
      desc: (
        <p>
          Sol is our Blockchain Platform Development Team Leader with a deep
          passion for programming and a love for all things open source. Sol is
          a true believer in the power of blockchain technology and is always
          looking for ways to showcase our skills through exciting new services.
          As the backbone of our platform development team, he is responsible
          for all the backend and server technologies that make our projects
          possible. So if you want to work with someone who is both passionate
          and skilled in the latest technologies, Sol is the person you want to
          talk to!
        </p>
      ),
      cardDesc: <p>Meet our Blockchain Platform Development Team Leader, Sol</p>
    },
    image: solKang,
  },
  {
    data: {
      name: "Jongin Lee",
      desc: (
        <p>
          Jongin is the genius behind our slick and user-friendly interfaces.
          Jongin's passion with UX/UI design ensures that even the most
          complicated blockchain services can be accessed with ease and fun.
          After all, Jongin knows that in order for blockchain technology to
          truly reach its full potential, it needs to connect with real people
          through great service design. And with him on our team, we're
          confident that we can make that happen. But don't let his playful and
          eccentric personality fool you. So if you want to create awesome user
          experiences with a side of laughs, Jongin is the one.
        </p>
      ),
      cardDesc: <p>Meet our user-friendly interfaces deveplover.</p>
    },
    image: jonginLee,
  },
  {
    data: {
      name: "Chanhui Lee",
      desc: (
        <p>
          Chanhui is our Smart Contract Superstar, the mastermind behind our
          ever-evolving architecture with a keen eye for service excellence and
          a knack for handling large-scale traffic. What really sets Chanhui
          apart is his passion for learning and his infectious enthusiasm for
          the future. Chanhui is not just a smart cookie - he’s also a
          fun-loving member of the team, always ready with a joke or a clever
          quip. And with Chanhui's talent for smart contract development,
          world-changing ideas we'll come up with next! So come along for the
          ride and join us in creating a brighter blockchain-powered future with
          Chanhui leading the way!
        </p>
      ),
      cardDesc: <p>Meet our Smart Contract Superstar Chanhui Lee</p>
    },
    image: chanhuiLee,
  },
];
