import './App.css';
import './index.css';
import Body from './component/body';
import Header from './component/header';
import Footer from './component/footer';
import 'semantic-ui-css/semantic.min.css'
function App() {
  return (
    <div className="body" >
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
