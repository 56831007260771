import React, {useState} from 'react'
import { Button, Image, Modal, Icon } from 'semantic-ui-react'
import MemberCard from './memberCard'
import styled from 'styled-components';

export default function ModalExampleBasic( {dataObject, index }) {
  const [open, setOpen] = useState(false)

  return (
    <ResponsiveModal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      dimmer={"inverted"}
      trigger={
        <MemberCard
          setOpen={setOpen}
          open={open}
          dataObject={dataObject}
          index={index}
        />
      }
    >
      <Modal.Header
        style={{
          textAlignLast: "right",
          height: "10px",
          borderBottom: " none",
        }}
      >
        <Icon
          basic
          name="delete"
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(false)}
        />
      </Modal.Header>
      <Modal.Content image>
        <Image size="medium" src={dataObject.image} />
        <Modal.Description style={{ position: "relative", width: "80%" }}>
          <h2 style={{ marginTop: "20px" }}>{dataObject.data.name}</h2>
          <AbsoluteSpan>{dataObject.data.desc}</AbsoluteSpan>
        </Modal.Description>
      </Modal.Content>
    </ResponsiveModal>
  );
}

export const AbsoluteSpan = styled.span`
  position: absolute;
  top: 20%; 
  @media only screen and (max-width: 767px) {
    top: auto;
  }
`;
export const ResponsiveModal = styled(Modal)`
min-height: 300px;
@media only screen and (max-width: 767px) {
    width: 80%;
    height: 760px;
  }
`