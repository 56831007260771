import React from 'react'
import { Dropdown, Icon , Popup, Grid, Button} from 'semantic-ui-react'
import styled from 'styled-components'
function DropdownTopMenu({menuTitle, tapTitle, dropdownMenu}){

    const externalLink = (link) => {
        window.location.href= link;
    }
   return (
     <PopupResponsive basic
       trigger={
         <Button icon style={{ backgroundColor: "#ffffff", fontSize: " 18px", flex: 1, display:'flex' }}>
           <div style={{ marginRight: '5px'}}>{menuTitle}</div>
           <Icon  name="grid layout" />
         </Button>
       }
       hoverable position='bottom right'
     >
       <ResponsiveGrid divided  >
         <Grid.Column   >
           <div
             style={{
               display: "flex",
               marginBottom: "10px",
               alignItems: "center ",
               boxShadow: 'unset'
              
             }}
           >
             <div
               style={{
                 fontWeight: "500",
                 fontSize: "12px",
                 lineHeight: "24px",
                 color: "#777",
                 display: "inline",
                
                 flexShrink: "0",
               }}
             >
               {tapTitle}
             </div>
             <div
               style={{
                 marginLeft: "3px",
                 flex: 1,
                 backgroundColor: "#f2f2f2",
                 height: "1px",
               }}
             />
           </div>
           <Grid columns={2} style={{marginLeft: '20px'}} stackable >
             {dropdownMenu.menuData.map((e) => (
                 <GridColumn >
               <DropdownItem  >
                 <LinkButton onClick={() => externalLink(e.url)}>
                   <div style={{ marginRight: "10px", width:'70px', height:'70px' }}>
                     <img
                     style={{width: '100%', height:'100%', objectFit:'fill'}}
                       alt="itemImg"
                       src={e.image}
                     ></img>
                   </div>
                   <div>
                     <FontDiv>{e.name}</FontDiv>
                     <div>See More <Icon name='angle right' color='black'/></div>
                   </div>
                 </LinkButton>
               </DropdownItem>
               </GridColumn>

             ))}
            
           </Grid>
         </Grid.Column>
       </ResponsiveGrid>
     </PopupResponsive>
   );
}
export const LinkButton = styled.div`
  position: relative;
  display: flex;
  width: 350px;
  align-items: center;
  height: 100%;
  border-radius: 16px;
  cursor: pointer;
  &:hover {
    background-color: #f5f8ff !important;
    color: #3478f6 !important;
  }
  @media only screen and (max-width: 767px) {
    width: 300px !important;
    height: 90%;
  }
`;
export const FontDiv = styled.div`
font-weight: 700;
font-size: 18px;
line-height: 24px;
&:hover{  
    color : #3478f6 !important;
  }
`
export const PopupButton = styled(Button)`
background-color: rgb(255, 255, 255);
    font-size: 18px;
    flex: 1 1 0%;
    display: flex;
`
export const PopupResponsive = styled(Popup)`
  width: 00px ;

  @media only screen and (max-width: 767px) {
    width: 300px;
  }
`;
export const ResponsiveGrid = styled(Grid)`
width: 800px !important;

@media only screen and (max-width: 767px) {
  width: 350px !important;
}
`

export const GridColumn = styled(Grid.Column)`
width: '45%'
@media only screen and (max-width: 767px) {
  height: 100px !important;
}
`
export const DropdownItem = styled(Dropdown.Item)`
margin: 10px; height:110px;, padding-left: 15px;
@media only screen and (max-width: 767px) {
  height: 90px !important;
}
`

export default DropdownTopMenu