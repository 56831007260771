import styled from 'styled-components';
import ModalExampleModal from './memberModal';
import React, { useState } from 'react';
export default function MemberCard({setOpen,open, dataObject, index}) {
   
    return (
      <>
        <MainCardDiv onClick={()=> setOpen(!open) }>
     
          <ImgDiv>
            <MemberImg src={dataObject.image} alt="memberImg" />
            <NameDiv>
              <span style={{ fontSize: "26px" }}>{dataObject.data.name}</span>
              <span style={{ color: "#CCCCCC" }}>Read More</span>
            </NameDiv>
            <RelativeDiv>
              <MemberSpan
              >
                {dataObject.data.cardDesc}
              </MemberSpan>
            </RelativeDiv>
          </ImgDiv>
        </MainCardDiv>
      </>
    );
  }

  export const MainCardDiv = styled.div`
  
    width: 270px;
    height: 440px;
    margin: 0px 5px 0px 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    cursor: pointer;
    :hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
      }
  `;

  export const ImgDiv = styled.div`
    width: 270px;
    height: 300px;
  `;
  export const MemberImg = styled.img`
    width: 99%;
    height: 100%;
  `;
  export const NameDiv = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 0px 10px;
  `;
  export const RelativeDiv = styled.div`
    height: 40%;
    position: relative;
  `;
  export const MemberSpan = styled.span`
  position: absolute;
  left: 5%;
  top: 25%;
  line-height: 25px;
  

  `