import biyardLogo from '../../img/biyardLogo.png'
import madAppLogo from '../../img/madAppLogo.png'
import byFactoryLogo from '../../img/byFactoryLogo.png'
import prenetLogo from '../../img/prenetLogo.png'
import bySuitsLogo from '../../img/bySuitsLogo.png'
export const homePagesData = {
  tabName: "HomePage",
  menuData: [
    {
      name: "Biyard",
      url: "https://www.biyard.co/",
      image: biyardLogo,
    },
    {
      name: "Mad App",
      url: "https://madapp.io/",
      image: madAppLogo,
    },
  ],
};

export const ServiceData = {
  tabName: "Service",
  menuData: [
    {
      name: "ByFactory",
      url: "https://Byfactory.io/",
      image: byFactoryLogo,
    },
    {
      name: "Console",
      url: "https://console.prenet.blockchainbusan.kr/auth/login",
      image: prenetLogo,
    },

    {
      name: "BySuits",
      url: "https://chrome.google.com/webstore/detail/bysuits/pfgkpcfidiihihncpmogdicaohmhlkak",
      image: bySuitsLogo,
    },
    {
      name: "Explorer",
      url: "https://explorer.prenet.blockchainbusan.kr",
      image: prenetLogo,
    },
  ],
};