import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";

export default function CardSlider({dataObject}) {

    return (
      <>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
        //   autoplay={{
        //     delay: 5000,
        //     disableOnInteraction: false,
        //   }}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="cardSlider"
        >
          {dataObject.map((e, i) => {
           
            return (
              <>
                <div style={{  border: "1px solid", display:'flex' }}>
                  <SwiperSlide>
                    <div style={{width:'300px', height:'300px'}}><img style={{height:'100%', width:'100%'}} src={e.image} alt='memberImage' /> 
                      
                        </div> < DescDiv>{e.data.desc}</DescDiv></SwiperSlide>
                </div>
              </>
            );
          })}
          
        </Swiper>
      </>
    );
  }
export const DescDiv = styled.div`
padding-left: 10px;
padding-right: 10px;
width:65%;
font-style: italic;
`